//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh !important;
    width: 100vw !important;
}

#login-form {
    max-width: 330px;
    padding: 15px;
    text-align: center!important;

    .form-floating:focus-within {
        z-index: 2;
    }
}

#imageUploadArea .form-control::file-selector-button {
    margin-right: 15px!important;
}

#imgReset, #imgPreviewSrc, #imgButtonProcess, #imgButtonDownloadWatermarked {
    display: none;
}
//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";

//---------------------------------------------
// 4.Utilities
//---------------------------------------------
